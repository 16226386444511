import React, { useEffect } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import GlobalStyle from 'theme/GlobalStyle';
import { theme as mainTheme } from 'theme/mainTheme';
import NavigationProvider from 'contexts/NavigationContext';
import 'react-medium-image-zoom/dist/styles.css';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding-top: ${({ theme }) => theme.navSize.mobile};
  ${({ theme }) => theme.mq.lg} {
    padding-top: ${({ theme }) => theme.navSize.desktop};
  }
`;

const MainLayout = ({ children }) => {
  useEffect(() => {
    const YLScript = document.createElement('script');
    YLScript.type = 'text/javascript';
    YLScript.text = `
      var youleadId = 'matexi';
      var youleadDC = true;
      (function () {
        var yl = document.createElement('script');
        yl.type = 'text/javascript';
        yl.async = true;
        yl.src =
          ('https:' == document.location.protocol ? 'https://' : 'http://') +
          'm-' +
          youleadId +
          '.youlead.pl/m.js?ts=' +
          new Date().getTime();
        document.getElementsByTagName('body')[0].appendChild(yl);
      })();
    `;
    document.body.appendChild(YLScript);
  }, []);

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      <NavigationProvider>
        <Wrapper id="home">{children}</Wrapper>
      </NavigationProvider>
    </ThemeProvider>
  );
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MainLayout;
