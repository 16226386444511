import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
  const [favsLength, setFavsLength] = useState(0);
  const [isNavHidden, setIsNavHidden] = useState(false);
  const [isSidenavVisible, setIsSidenavVisible] = useState(false);

  let prevScroll = 0;

  const handleScroll = e => {
    const window = e.currentTarget;

    if (prevScroll > window.scrollY) {
      setIsNavHidden(false);
    } else if (window.scrollY >= 100) {
      setIsNavHidden(true);
    }
    prevScroll = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', e => handleScroll(e));

    return () => {
      window.removeEventListener('scroll', e => handleScroll(e));
    };
  }, []);

  const hideNav = () => {
    if (window.scrollY >= 100) setIsNavHidden(true);
  };

  const closeSidenav = () => {
    if (isSidenavVisible) {
      setIsSidenavVisible(false);
      setTimeout(hideNav, 800);
      document.body.style.overflow = 'auto';
    }
  };

  const openSidenav = () => {
    if (!isSidenavVisible) {
      setIsSidenavVisible(true);
      document.body.style.overflow = 'hidden';
    }
  };

  return (
    <NavigationContext.Provider
      value={{
        isNavHidden,
        isSidenavVisible,
        hideNav,
        closeSidenav,
        openSidenav,
        favsLength,
        setFavsLength,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

NavigationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default NavigationProvider;
